/* [Master Stylesheet v-1.0.0] */
/* :: 1.0 Import Web Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Ubuntu:300,400,500,700");
/* :: 2.0 Import All CSS */
@import url(bootstrap.min.css);
@import url(classy-nav.min.css);
@import url(animate.css);
@import url(font-awesome.min.css);
/* :: 3.0 Common/Base CSS */
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  margin-top: 85px;
}
@media only screen and (max-width: 767px) {
  body {
    margin-top: 70px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  line-height: 1.3;
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
}

p {
  color: #787878;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
}

a {
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  text-decoration: none;
  outline: 0 solid transparent;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
}
a:hover,
a:focus {
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  text-decoration: none;
  outline: 0 solid transparent;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
}

ul,
ol {
  margin: 0;
}
ul li,
ol li {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
}

/* Spacing */
.mt-15 {
  margin-top: 15px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

/* Height */
.height-400 {
  height: 400px !important;
}

.height-500 {
  height: 500px !important;
}

.height-600 {
  height: 600px !important;
}

.height-700 {
  height: 700px !important;
}

.height-800 {
  height: 800px !important;
}

/* Section Padding */
.section-padding-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-padding-80-0 {
  padding-top: 80px;
  padding-bottom: 0;
}

.section-padding-0-80 {
  padding-top: 0;
  padding-bottom: 80px;
}

/* Section Heading */
.section-heading {
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}
.section-heading h2 {
  font-size: 30px;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
}
.section-heading.text-left {
  text-align: left !important;
}

/* Miscellaneous */
.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-dark {
  background-color: #000000 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.bg-overlay,
.bg-overlay-white {
  position: relative;
  z-index: 2;
  background-position: center center;
  background-size: cover;
}
.bg-overlay:hover,
.bg-overlay-white:hover {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.bg-overlay:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-overlay-white:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Button */
.btn {
  display: inline-block;
  min-width: 170px;
  height: 50px;
  color: #ffffff;
  border: none;
  border-radius: 0;
  padding: 0 40px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 50px;
  background-color: #0315ff;
  letter-spacing: 1.5px;
  font-weight: 600;
}
.btn:hover,
.btn:focus {
  color: #ffffff;
  background-color: #dc0345;
}

/* :: 4.0 Header Area CSS */
.header_area {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
@media only screen and (max-width: 767px) {
  .header_area .classy-nav-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .header_area .classy-nav-container .classy-navbar-toggler {
    margin-left: auto;
    margin-right: 15px;
  }
}
.header_area .classy-navbar,
.header_area .header-meta {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  min-width: 100%;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header_area .classy-navbar,
  .header_area .header-meta {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
  }
}
.header_area .classy-navbar {
  height: 85px;
  padding: 5px 0 5px 5%;
}
@media only screen and (max-width: 767px) {
  .header_area .classy-navbar {
    height: 70px;
    border-bottom: 1px solid #ebebeb;
  }
}
.header_area .header-meta {
  height: 85px;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .header_area .header-meta {
    height: 40px;
  }
}
.header_area .classynav ul li a {
  font-size: 16px;
  color: #787878;
}
.header_area .classynav ul li a:hover {
  color: #000000;
}
.header_area .classynav ul li .megamenu li a,
.header_area .classynav ul li .dropdown li a {
  font-size: 14px;
  color: #787878;
}
.header_area .classynav ul li .megamenu li a:hover,
.header_area .classynav ul li .dropdown li a:hover {
  color: #000000;
}
.header_area .search-area form {
  position: relative;
  z-index: 1;
  height: 85px;
  border-left: 1px solid #ebebeb;
}
.header_area .favourite-area a,
.header_area .user-login-info a,
.header_area .cart-area a {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  width: 90px;
  display: block;
  text-align: center;
  border-left: 1px solid #ebebeb;
  height: 100%;
  line-height: 80px;
}
@media only screen and (max-width: 767px) {
  .header_area .favourite-area a,
  .header_area .user-login-info a,
  .header_area .cart-area a {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    width: 50px;
    line-height: 40px;
  }
}
.header_area .favourite-area a img,
.header_area .user-login-info a img,
.header_area .cart-area a img {
  max-width: 20px;
}
.header_area .favourite-area a span,
.header_area .user-login-info a span,
.header_area .cart-area a span {
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  color: #0315ff;
  font-weight: 700;
  position: absolute;
  top: -10px;
}
@media only screen and (max-width: 767px) {
  .header_area .favourite-area a span,
  .header_area .user-login-info a span,
  .header_area .cart-area a span {
    font-size: 14px;
  }
}
.header_area.sticky {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

.cart-bg-overlay.cart-bg-overlay-on {
  -webkit-transition-duration: 1000ms;
  transition-duration: 1000ms;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1050;
}

/* Cart Area CSS */
.right-side-cart-area {
  position: fixed;
  width: 670px;
  height: 100%;
  top: 0;
  right: -800px;
  background-color: #ffffff;
  z-index: 1100;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .right-side-cart-area {
    width: 500px;
    right: -600px;
  }
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area {
    width: 270px;
    right: -400px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-side-cart-area {
    width: 400px;
    right: -550px;
  }
}
.right-side-cart-area.cart-on {
  right: 0;
}
.right-side-cart-area .cart-button a {
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 100;
  width: 90px;
  display: block;
  text-align: center;
  border-left: 1px solid #ebebeb;
  height: 85px;
  line-height: 80px;
  background-color: #f5f7f9;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-button a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.right-side-cart-area .cart-button a img {
  max-width: 20px;
}
.right-side-cart-area .cart-button a span {
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  color: #0315ff;
  font-weight: 700;
  position: absolute;
  top: -10px;
}
.right-side-cart-area .cart-content {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}
.right-side-cart-area .cart-content .cart-list {
  position: relative;
  z-index: 10;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 190px;
  flex: 0 0 190px;
  width: 190px;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    width: 150px;
  }
}
.right-side-cart-area .cart-content .cart-list .single-cart-item {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image {
  position: relative;
  z-index: 1;
  display: block;
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px 15px 15px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area
    .cart-content
    .cart-list
    .single-cart-item
    .product-image
    .cart-item-desc {
    padding: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-side-cart-area
    .cart-content
    .cart-list
    .single-cart-item
    .product-image
    .cart-item-desc {
    padding: 30px 20px;
  }
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  .product-remove {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ffffff;
  font-size: 12px;
  padding: 5px;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area
    .cart-content
    .cart-list
    .single-cart-item
    .product-image
    .cart-item-desc
    .product-remove {
    top: 5px;
    right: 5px;
  }
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  .badge {
  display: block;
  font-weight: 600;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-bottom: 5px;
  text-align: left;
  padding: 0;
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  h6 {
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area
    .cart-content
    .cart-list
    .single-cart-item
    .product-image
    .cart-item-desc
    h6 {
    margin-bottom: 10px;
  }
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  .size,
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  .color {
  display: block;
  font-weight: 600;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1;
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item
  .product-image
  .cart-item-desc
  .price {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 30px;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area
    .cart-content
    .cart-list
    .single-cart-item
    .product-image
    .cart-item-desc
    .price {
    margin-top: 10px;
    font-size: 12px;
  }
}
.right-side-cart-area
  .cart-content
  .cart-list
  .single-cart-item:hover
  .product-image
  .cart-item-desc {
  background-color: rgba(0, 0, 0, 0.8);
}
.right-side-cart-area .cart-content .cart-amount-summary {
  padding: 100px 10%;
  position: relative;
  z-index: 10;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 190px);
  flex: 0 0 calc(100% - 190px);
  width: calc(100% - 190px);
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
    width: calc(100% - 100px);
    padding: 50px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 150px);
    flex: 0 0 calc(100% - 150px);
    width: calc(100% - 150px);
    padding: 50px 30px;
  }
}
.right-side-cart-area .cart-content .cart-amount-summary h2 {
  font-size: 30px;
  margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary h2 {
    font-size: 30px;
    margin-bottom: 50px;
  }
}
.right-side-cart-area .cart-content .cart-amount-summary .summary-table li {
  margin-bottom: 20px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary .summary-table li {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary .summary-table li {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
.right-side-cart-area
  .cart-content
  .cart-amount-summary
  .summary-table
  li
  span:last-child {
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
}
@media only screen and (max-width: 767px) {
  .right-side-cart-area .cart-content .cart-amount-summary .btn {
    min-width: 140px;
    width: 140px;
    padding: 0 15px;
  }
}

/* :: 5.0 Welcome Area CSS */
.welcome_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 300px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_area {
    height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .welcome_area {
    height: 250px;
  }
}
.welcome_area .hero-content h6 {
  font-size: 18px;
  color: #787878;
  margin-bottom: 10px;
}
.welcome_area .hero-content h2 {
  font-size: 60px;
  color: #000000;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_area .hero-content h2 {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .welcome_area .hero-content h2 {
    font-size: 30px;
  }
}

/* :: 6.0 Top Catagory Area CSS */
.single_catagory_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 240px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_catagory_area {
    height: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .single_catagory_area {
    height: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_catagory_area {
    margin-bottom: 30px;
  }
}
.single_catagory_area:after {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
.single_catagory_area .catagory-content a {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_catagory_area .catagory-content a {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .single_catagory_area .catagory-content a {
    font-size: 18px;
  }
}
.single_catagory_area:hover .catagory-content a {
  color: #0315ff;
}

@keyframes favme-anime {
  0% {
    opacity: 1;
    font-size: 14px;
    -webkit-text-stroke-color: transparent;
  }
  25% {
    opacity: 0.6;
    color: #ffffff;
    font-size: 15px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #dc0345;
  }
  75% {
    opacity: 0.6;
    color: #ffffff;
    font-size: 15px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #dc0345;
  }
  100% {
    opacity: 1;
    font-size: 14px;
    -webkit-text-stroke-color: transparent;
  }
}

